import * as React from "react";
import { graphql } from "gatsby";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import NotFoundImage from "../images/img_404.png";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../config/theme";

import { Container, Box, Typography, Button } from "@mui/material";

// markup
const NotFoundPage = () => {
    const { siteUrl } = useI18next();

    const backToHome = () => {
        window.open(`${siteUrl}`, "_self");
    };

    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Box component={"title"}>404</Box>
                <Box p={10} display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                    <Box component={"img"} src={NotFoundImage} />
                    <Typography sx={{ fontSize: "h2.fontSize" }}>Oops</Typography>
                    <Typography>
                        {" "}
                        <Trans>page_not_found</Trans>{" "}
                    </Typography>
                    <Button sx={{ m: 5 }} variant="contained" onClick={backToHome}>
                        <Trans>back_to_home</Trans>
                    </Button>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default NotFoundPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
